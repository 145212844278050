li:hover {
  cursor: pointer;
}

.App {
  height: 100%;
  width: 100%;
}

.navbar {
  width: 100;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  background-color: black;
}

  .navbar-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 50vw;
  }

  .navbar-text {
    color: lightgrey;
  }

  .navbar-text-selected {
    color: white;
    font-weight: bold;
  }

  li {
    padding: 10px;
  }

.body {
  border: 1px solid black;
}
